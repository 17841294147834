@charset "utf-8";

// @tailwind base;
// @tailwind components;
// @tailwind utilities;
// @tailwind screens;



/**
 *
 *  Global
 *
 */

/* ==========================================================================
	 Header
=========================================================================== */
.g-header {
  padding: 40px;
  background: linear-gradient(top, rgba(34, 34, 34, 0.5) 0%, rgba(34, 34, 34, 0) 100%);
  background: linear-gradient(top, rgba(34, 34, 34, 0.5) 0%, rgba(34, 34, 34, 0) 100%);
  background: linear-gradient(to bottom, rgba(34, 34, 34, 0.5) 0%, rgba(34, 34, 34, 0) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#80222222', endColorstr='#00222222', GradientType=0);
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1), padding 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1), padding 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  backface-visibility: hidden;
  backface-visibility: hidden;
}

/* active */
.g-header.is-active {
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
}

.g-header__inner {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  box-align: center;
  flex-align: center;
}

.g-header__nav {
  margin: 0 0 0 auto;
}

/* .g-header__logo
----------------------------------------------------------------- */
.g-header__logo {
  position: relative;
  height: 65px;
}

.g-header__logo img {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.g-header__logo .active {
  opacity: 0;
}

/* active */
.g-header.is-active .g-header__logo .default {
  opacity: 0;
}

.g-header.is-active .g-header__logo .active {
  opacity: 1;
}

/* .g-header__menu-btn
----------------------------------------------------------------- */
.g-header__menu-btn {
  display: none;
}

/* media query -> min-pc
================================================================= */
@media only screen and (max-width: 1560px) {
  .g-header {
    padding: 40px 20px;
  }

  .g-header.is-active {
    padding: 20px;
  }

  .g-header__logo {
    height: 50px;
  }

  .g-header__logo img {
    width: auto;
    height: 100%;
  }
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .g-header,
  .g-header.is-active {
    padding: 13px 10px;
  }

  .g-header__inner {
    display: block;
  }
  /* .g-header__logo
	----------------------------------------------------------------- */
  .g-header__logo {
    height: 17px;
  }
  /* .g-header__menu-btn
	----------------------------------------------------------------- */
  .g-header__menu-btn {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    display: block;
    margin: auto;
    width: 20px;
    height: 20px;
  }

  .g-header__menu-btn .menu-btn {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
  }

  .g-header__menu-btn .menu-btn span {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #ec6a00;
    transition: -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .g-header__menu-btn .menu-btn span:nth-child(1) {
    top: 4px;
  }

  .g-header__menu-btn .menu-btn span:nth-child(2) {
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .g-header__menu-btn .menu-btn span:nth-child(3) {
    bottom: 4px;
  }
  /* active */
  .g-header__menu-btn .menu-btn.is-active span:nth-child(1) {
    transform: translateY(5px) rotate(135deg);
    transform: translateY(5px) rotate(135deg);
  }

  .g-header__menu-btn .menu-btn.is-active span:nth-child(2) {
    transform: translateX(100%);
    transform: translateX(100%);
  }

  .g-header__menu-btn .menu-btn.is-active span:nth-child(3) {
    transform: translateY(-5px) rotate(-135deg);
    transform: translateY(-5px) rotate(-135deg);
  }
}


/* ==========================================================================
	 GlobalNavi
=========================================================================== */
.g-nav .main-nav {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  box-align: center;
  flex-align: center;
}

/* .g-nav main-nav
----------------------------------------------------------------- */
.g-nav .main-nav__item {
  position: relative;
}

.g-nav .main-nav__item a {
  display: block;
}

.g-nav .main-nav__item span {
  position: relative;
  display: inline-block;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 15px;
  transition: color 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: color 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

/* txt */
.g-nav .main-nav__item--txt a {
  padding: 0 15px;
}

.g-nav .main-nav__item--txt span::before,
.g-nav .main-nav__item--txt span::after {
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 2px;
  background: #fb8c0d;
  content: '';
  transition: width 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.g-nav .main-nav__item--txt span::before {
  right: 50%;
}

.g-nav .main-nav__item--txt span::after {
  left: 50%;
}

.g-nav .main-nav__item--contact span::before,
.g-nav .main-nav__item--contact span::after {
  display: none;
}

/* btn */
.g-nav .main-nav__item--btn {
  margin-left: 10px;
}

.g-nav .main-nav__item--btn span {
  padding: 15px;
  border: 2px solid #fff;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.g-nav .main-nav__item--contact {
  padding: 0;
}

.g-nav .main-nav__item--contact span {
  padding: 15px;
  width: 180px;
  border: 2px solid #fff;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

/* IE10~ */
@media all and (-ms-high-contrast: none) {
  .g-nav .main-nav__item--btn span {
    padding: 18px 30px 12px;
  }
}

/* hover / active */
.g-header.is-active .g-nav .main-nav__item--txt span {
  color: #000;
}

.mode-pc .g-nav .main-nav__item--txt a:hover span,
.g-nav .main-nav__item--txt.is-active span,
.g-header.is-active .g-nav .main-nav__item--txt.is-active span {
  color: #fb8c0d;
}

.mode-pc .g-nav .main-nav__item--txt a:hover span::before,
.mode-pc .g-nav .main-nav__item--txt a:hover span::after,
.g-nav .main-nav__item--txt.is-active span::before,
.g-nav .main-nav__item--txt.is-active span::after {
  width: 50%;
}

.g-header.is-active .g-nav .main-nav__item--btn span {
  border-color: #000;
  color: #000;
}

.mode-pc .g-nav .main-nav__item--btn a:hover span {
  border-color: #fb8c0d;
  color: #fb8c0d;
}

/* sub menu */
.g-nav .main-nav__item--txt.main-nav__item--sub > a {
  position: relative;
  top: 10px;
  padding-bottom: 20px;
}

.g-nav .main-nav__sub {
  position: absolute;
  top: 48px;
  left: -15px;
  width: calc(100% + 25px);
  height: auto;
}

.g-nav .main-nav__item--contact + .main-nav__sub {
  top: 65px;
  left: 15px;
  width: 180px;
}

.g-nav .main-nav__sub-item a {
  display: block;
  box-sizing: border-box;
  margin-top: -2px;
  padding: 15px 0;
  width: 100%;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: 0.2s background-color;
}

.g-nav .main-nav__sub-item a span::before,
.g-nav .main-nav__sub-item a span::after {
  display: none;
}

.g-nav .main-nav__sub-item a:hover {
  background-color: rgba(216, 125, 22, 0.8);
}

.g-nav .main-nav__sub-item a:hover span {
  color: #fff !important;
}

.g-nav .main-nav__sub-item:first-of-type a {
  margin: 0;
}

.g-nav .main-nav__item--txt.is-active .main-nav__sub-item a span {
  color: #fff;
}

.mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub:hover > a span {
  color: #fb8c0d;
}

.mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub:hover > a span::before,
.mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub:hover > a span::after {
  width: 50%;
}

.g-nav .main-nav__item--txt.main-nav__item--sub .main-nav__sub {
  overflow: hidden;
  max-height: 0;
  transition: 0.3s max-height;
}

.mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub:hover .main-nav__sub {
  overflow: hidden;
  max-height: 350px;
}

/* sub menu .is-active */
.g-header.is-active .g-nav .main-nav__item--txt.main-nav__item--sub > a {
  top: 21px;
  padding-bottom: 42px;
}

.g-header.is-active .g-nav .main-nav__sub {
  top: 82px;
}

.g-header.is-active .g-nav .main-nav__sub-item a {
  background-color: rgba(256, 256, 256, 1);
}

.g-header.is-active .g-nav .main-nav__sub-item a:hover {
  background-color: rgba(251, 139, 14, 1);
}

.g-header.is-active .g-nav .main-nav__sub-item a span {
  color: #000 !important;
}

.g-header.is-active .g-nav .main-nav__sub-item a:hover span {
  color: #fff !important;
}

.mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub .main-nav__sub.main-nav__sub--03 {
  margin: 0 0 0 -28px;
  width: 200px;
}

/* media query -> min-pc
================================================================= */
@media only screen and (max-width: 1560px) {
  .g-nav .main-nav__item span {
    font-size: 14px;
  }

  .g-nav .main-nav__item--txt a {
    padding: 0 6px;
  }

  .g-nav .main-nav__sub {
    position: absolute;
    left: -6px;
    width: calc(100% + 16px);
    height: auto;
  }

  .g-nav .main-nav__item--contact + .main-nav__sub {
    top: 63px;
    left: 6px;
  }

  .g-nav .main-nav__sub-item a {
    padding: 14px 0;
  }

  .g-nav .main-nav__sub-item a span {
    font-size: 13px;
  }

  .g-header.is-active .g-nav .main-nav__sub {
    top: 81px;
  }

  .mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub .main-nav__sub.main-nav__sub--03 {
    margin: 0 0 0 -30px;
    width: 180px;
  }
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .g-nav {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    overflow-y: scroll;
    max-height: 100svh;   // stylelint-disable-line
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
  }

  .g-nav .main-nav {
    display: block;
    padding: 43px 0;
  }
  /* active */
  .g-nav.is-active {
    display: block;
    opacity: 0;
  }
  /* .g-nav main-nav
	----------------------------------------------------------------- */
  .g-nav .main-nav__item {
    overflow: hidden;
    text-align: center;
  }

  .g-nav .main-nav__item + .main-nav__item {
    margin-top: 10px;
  }

  .g-nav .main-nav__item span {
    color: #333;
    font-weight: 900;
    font-size: 17px;
  }

  .g-nav .main-nav__item--contact span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 260px;
    height: 60px;
    border: none;
    background: #ed6d00;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
  }

  .mode-pc .g-nav .main-nav__item--txt.main-nav__item--sub .main-nav__sub.main-nav__sub--03 {
    margin: 0;
    width: auto;
  }
  /* txt */
  .g-nav .main-nav__item--txt a {
    padding: 5px 0;
  }

  .g-nav .main-nav__item--txt span::before,
  .g-nav .main-nav__item--txt span::after {
    display: none;
  }
  /* btn */
  .g-nav .main-nav__item--btn {
    margin: 30px 0 0 !important;
    padding: 0 30px;
  }

  .g-nav .main-nav__item--btn span,
  .g-header.is-active .g-nav .main-nav__item--btn span {
    display: block;
    border: none;
    background: #ed6d00;
    color: #fff;
    font-weight: 500;
  }

  /* sub menu */
  .g-nav .main-nav__item--txt.main-nav__item--sub > a {
    position: relative;
    top: 0;
    margin-bottom: 30px;
    padding-bottom: 0;
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > .main-nav__item--contact {
    margin-bottom: 0;
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > a::after {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-top: 2px solid #ed6d00;
    border-right: 2px solid #ed6d00;
    content: '';
    transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > a.is-active::after {
    transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > .main-nav__item--contact::after {
    top: 0;
    bottom: 0;
    left: 75%;
    margin: auto;
    border-color: #fff;
  }

  .g-nav .main-nav__sub {
    position: static;
    top: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
  }

  .g-nav .main-nav__sub-item a {
    top: 0;
    padding: 5px 0;
    border: none;
    background-color: rgba(256, 256, 256, 0) !important;
  }

  .g-nav .main-nav__item--contact {
    margin: 0 auto;
  }

  .g-nav .main-nav__item--contact + .main-nav__sub {
    margin: 0 auto;
    width: 260px;
  }

  .g-nav .main-nav__item--contact + .main-nav__sub a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 60px;
    border: 2px solid #ed6d00;
    background-color: transparent !important;
  }

  .g-nav .main-nav__item--contact + .main-nav__sub a span {
    color: #ed6d00;
  }

  .g-nav .main-nav__sub-item a:hover {
    background-color: rgba(256, 256, 256, 0) !important;
  }

  .g-nav .main-nav__sub-item a:hover span {
    color: #444 !important;
  }

  .g-nav .main-nav__sub-item:first-of-type a {
    border-bottom: none !important;
  }

  .g-nav .main-nav__item--txt.is-active .main-nav__sub-item a span {
    color: #444;
    font-size: 13px;
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > .main-nav__sub {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s max-height;
  }

  .g-nav .main-nav__item--txt.main-nav__item--sub > a.is-active + .main-nav__sub {
    overflow: hidden;
    max-height: 160px;
  }

  /* sub menu .is-active */
  .g-header.is-active .g-nav .main-nav__item--txt.main-nav__item--sub > a {
    top: 0;
    padding: 5px 0;
  }

  .g-header.is-active .g-nav .main-nav__sub-item {
    top: 0;
  }

  .g-header.is-active .g-nav .main-nav__sub-item a {
    background-color: rgba(256, 256, 256, 0) !important;
  }

  .g-header.is-active .g-nav .main-nav__sub-item a:hover {
    background-color: rgba(256, 256, 256, 0) !important;
  }

  .g-header.is-active .g-nav .main-nav__sub-item a span {
    color: #444 !important;
  }

  .g-header.is-active .g-nav .main-nav__sub-item a:hover span {
    color: #444 !important;
  }

  /* career */
  .g-nav .main-nav__item--txt.main-nav__item--sub.is-active > a + .main-nav__sub .main-nav__sub-item--career a span,
  .g-nav .main-nav__item--txt.main-nav__item--sub.is-active > a + .main-nav__sub .main-nav__sub-item--career a:hover span {
    color: #fb8c0d !important;
  }
}


/* ==========================================================================
	 Footer
=========================================================================== */
.g-footer {
  background: #0e0e0e;
}

/* .g-footer__nav
----------------------------------------------------------------- */
.g-footer__nav {
  position: relative;
  border-bottom: 1px solid #363636;
}

.g-footer__nav__inner {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  padding: 60px 0 100px;
  box-pack: justify;
  flex-pack: justify;
}

.g-footer__nav .item {
  flex-grow: 1;
  flex-grow: 1;
  box-flex: 1;
  flex-positive: 1;
}

/* upper */
.g-footer__nav .item__upper {
  line-height: 1.8;
}

.g-footer__nav .item__upper + .item__upper {
  margin-top: 12px;
}

.g-footer__nav .item__upper a {
  position: relative;
  display: inline-block;
  padding-left: 22px;
}

.g-footer__nav .item__upper a::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 5px 0 5px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #fb8c0d;
  content: '';
}

.g-footer__nav .item__upper--sp {
  display: none;
}

/* IE10~ */
@media all and (-ms-high-contrast: none) {
  .g-footer__nav .item__upper a::before {
    top: 0.4em;
    bottom: auto;
  }
}

.g-footer__nav .item__upper span {
  color: #fff;
  vertical-align: middle;
  font-size: 15px;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* under */
.g-footer__nav .item__under {
  overflow: hidden;
  margin-top: 20px;
}

.g-footer__nav .item__under__list {
  float: left;
}

.g-footer__nav .item__under__list + .item__under__list {
  margin-left: 48px;
}

.g-footer__nav .item__under__list a {
  color: #969696;
  font-size: 13px;
  line-height: 2;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.g-footer__nav .item__under__list a::before {
  margin-right: 10px;
  content: '－';
}

/* hover */
.mode-pc .g-footer__nav .item__upper a:hover span {
  color: #fb8c0d;
}

.mode-pc .g-footer__nav .item__under__list a:hover {
  color: #fff;
}

/* page-top */
.g-footer__nav__page-top {
  position: absolute;
  right: 0;
  bottom: 0;
}

.g-footer__nav__page-top a {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background: #333;
  transition: background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.g-footer__nav__page-top a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 0 8px 9px;
  border-style: solid;
  border-color: transparent transparent #fff;
  content: '';
}

/* hover */
.mode-pc .g-footer__nav__page-top a:hover {
  background: #fb8c0f;
}

.mode-pc .g-footer__nav__page-top a:hover::before {
  animation-name: moveTop;
  animation-name: moveTop;
  animation-duration: 0.8s;
  animation-duration: 0.8s;
  animation-delay: 0s;
  animation-delay: 0s;
  animation-fill-mode: both;
  animation-fill-mode: both;
}

/* .g-footer__btm
----------------------------------------------------------------- */
.g-footer__btm__inner {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  justify-content: space-between;
  padding: 28px 0;
  box-align: center;
  box-pack: justify;
  flex-align: center;
  flex-pack: justify;
}

/* list */
.g-footer__btm__list {
  letter-spacing: -0.4em;
}

.g-footer__btm__list li {
  display: inline-block;
  padding-right: 15px;
  letter-spacing: 0;
}

.g-footer__btm__list li + li {
  padding-left: 15px;
  border-left: 1px solid #363636;
}

.g-footer__btm__list a {
  color: #c0c0c0;
  font-size: 12px;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* hover */
.mode-pc .g-footer__btm__list a:hover {
  color: #fff;
}

/* copy */
.g-footer__btm__copy small {
  color: #c0c0c0;
  font-size: 11px;
}


/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  /* .g-footer__nav
	----------------------------------------------------------------- */
  .g-footer__nav__inner {
    display: block;
    padding: 20px 0;
  }
  /* upper */
  .g-footer__nav .item__upper {
    line-height: 1;
  }

  .g-footer__nav .item__upper + .item__upper {
    margin-top: 0;
  }

  .g-footer__nav .item__upper a {
    display: block;
    padding: 9px 0 10px 15px;
  }

  .g-footer__nav .item__upper a::before {
    border-width: 4px 0 4px 8px;
    transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .g-footer__nav .item__upper a.is-active::before {
    transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .g-footer__nav .item__upper span {
    font-size: 13px;
  }

  .g-footer__nav .item__upper--pc {
    display: none;
  }

  .g-footer__nav .item__upper--sp {
    display: block;
  }
  /* under */
  .g-footer__nav .item__under {
    overflow: hidden;
    margin-top: 0;
    height: 0;
  }

  .g-footer__nav .item__under__list {
    float: none;
    padding-left: 15px;
  }

  .g-footer__nav .item__under__list + .item__under__list {
    margin-left: 0;
  }

  .g-footer__nav .item__under__list a {
    display: block;
    padding: 5px 0;
    font-size: 12px;
    line-height: 1.3;
  }

  .g-footer__nav .item__under__list a::before {
    margin-right: 10px;
    content: '－';
  }
  /* page-top */
  .g-footer__nav__page-top {
    display: none;
  }
  /* .g-footer__btm
	----------------------------------------------------------------- */
  .g-footer__btm__inner {
    display: block;
    padding: 15px 0;
  }
  /* list */
  .g-footer__btm__list {
    text-align: center;
  }

  .g-footer__btm__list li {
    padding-right: 8px;
    line-height: 1.2;
  }

  .g-footer__btm__list li + li {
    padding-left: 8px;
  }

  .g-footer__btm__list a {
    font-size: 9px;
  }
  /* copy */
  .g-footer__btm__copy {
    margin-top: 5px;
    text-align: center;
  }

  .g-footer__btm__copy small {
    font-size: 9px;
  }
}


/* ==========================================================================
	 Conversion
=========================================================================== */
.g-conversion {
  position: relative;
  overflow: hidden;
}

.g-conversion .btns {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 0 30px;
}

.g-conversion a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 90px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    background: #000;
  }

  .ico {
    &--conversion {
      width: 26px;
    }

    &--seif {
      width: 33px;
    }
  }
}

.g-conversion a img {
  margin-right: 20px;
}

.g-conversion::before,
.g-conversion::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.g-conversion::before {
  background: url(/assets/img/bg_conversion.jpg) no-repeat 50%;
  background-size: cover;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.g-conversion::after {
  background: #151515;
  opacity: 0.75;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
  transition: opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.g-conversion__inner {
  position: relative;
  z-index: 1;
  padding: 140px 0 80px;
  vertical-align: middle;
  text-align: center;
}

.g-conversion__inner::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
  margin: auto;
  width: 1px;
  height: 76px;
  background: #fff;
  content: '';
  opacity: 0.6;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
}

.g-conversion__hdg {
  margin-bottom: 40px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 38px;
  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* IE10~ */
@media all and (-ms-high-contrast: none) {
  .g-conversion__hdg span::before {
    top: 0.15em;
    bottom: auto;
  }
}

.g-conversion__txt {
  margin-top: 20px;
  color: #fff;
  font-size: 15px;
}

/* media query -> sp
================================================================= */
@media only screen and (max-width: 767px) {
  .g-conversion .btns {
    display: block;

    .ico {
      &--conversion {
        width: 23px;
      }

      &--seif {
        width: 26px;
      }
    }
  }

  .g-conversion a {
    margin: 0 auto;
    width: 260px;
    height: 60px;
    font-size: 14px;

    & + a {
      margin-top: 15px;
    }
  }

  .g-conversion a img {
    margin-right: 10px;
  }

  .g-conversion__inner::before {
    height: 38px;
  }

  .g-conversion__hdg {
    letter-spacing: 1px;
    font-size: 19px;
  }

  .g-conversion__txt {
    margin-top: 10px;
    font-size: 12px;
  }
}

.bg-orange {
  background-color: #f96430 !important;
}

.bg-primary {
  background-color: #fc9009 !important;
}

.bg-f7 {
  background-color: #f7f7f7 !important;
}

.bg-ivory {
  background-color: #fff9f1 !important;
}

.border-primary {
  border-color: #fc9009 !important;
}

.border-ed {
  border-color: #ededed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-0 {
  border-width: 0 !important;
}

.border {
  border-width: 1px !important;
}

.border-t-3 {
  border-top-width: 3px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.grid {
  display: grid !important;
}

.table {
  display: table !important;
}

.hidden {
  display: none !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.order-last {
  order: 9999 !important;
}

.clearfix::after {
  display: table !important;
  clear: both !important;
  content: '' !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.list-disc {
  list-style-type: disc !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.max-w-730 {
  max-width: 730px !important;
}

.max-w-930 {
  max-width: 930px !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-5 {
  padding: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.resize {
  resize: both !important;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #fc9009 !important;
}

.hover\:text-orange:hover {
  color: #f96430 !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-11 {
  font-size: 11px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

.italic {
  font-style: italic !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.underline {
  text-decoration: underline !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.transform {
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;

  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.duration-300 {
  transition-duration: 300ms !important;
}

@media (min-width: 768px) {
  .md\:block {
    display: block !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:items-center {
    align-items: center !important;
  }

  .md\:order-first {
    order: -9999 !important;
  }

  .md\:float-right {
    float: right !important;
  }

  .md\:float-left {
    float: left !important;
  }

  .md\:my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .md\:-mx-5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .md\:-mx-20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:ml-4 {
    margin-left: 1rem !important;
  }

  .md\:mt-15 {
    margin-top: 15px !important;
  }

  .md\:mt-20 {
    margin-top: 20px !important;
  }

  .md\:mr-20 {
    margin-right: 20px !important;
  }

  .md\:mb-20 {
    margin-bottom: 20px !important;
  }

  .md\:mt-30 {
    margin-top: 30px !important;
  }

  .md\:mr-30 {
    margin-right: 30px !important;
  }

  .md\:mb-30 {
    margin-bottom: 30px !important;
  }

  .md\:mr-50 {
    margin-right: 50px !important;
  }

  .md\:ml-50 {
    margin-left: 50px !important;
  }

  .md\:mt-60 {
    margin-top: 60px !important;
  }

  .md\:mt-70 {
    margin-top: 70px !important;
  }

  .md\:mt-120 {
    margin-top: 120px !important;
  }

  .md\:p-40 {
    padding: 40px !important;
  }

  .md\:px-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .md\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:pt-50 {
    padding-top: 50px !important;
  }

  .md\:pr-50 {
    padding-right: 50px !important;
  }

  .md\:pl-50 {
    padding-left: 50px !important;
  }

  .md\:pb-80 {
    padding-bottom: 80px !important;
  }

  .md\:pt-100 {
    padding-top: 100px !important;
  }

  .md\:pb-110 {
    padding-bottom: 110px !important;
  }

  .md\:text-13 {
    font-size: 13px !important;
  }

  .md\:text-14 {
    font-size: 14px !important;
  }

  .md\:text-16 {
    font-size: 16px !important;
  }

  .md\:text-20 {
    font-size: 20px !important;
  }

  .md\:text-21 {
    font-size: 21px !important;
  }

  .md\:text-24 {
    font-size: 24px !important;
  }

  .md\:text-26 {
    font-size: 26px !important;
  }

  .md\:text-33 {
    font-size: 33px !important;
  }

  .md\:text-36 {
    font-size: 36px !important;
  }

  .md\:text-40 {
    font-size: 40px !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }
}

// @tailwind utilities;
